import {BreedList} from '@/components/BreedList';
import {DogBreedData} from '@/types';
import {useCallback, useEffect} from 'react';
import {
    AutocompleteInput,
    Create,
    ReferenceInput,
    SimpleForm,
    useGetList,
    useGetOne,
    useInput,
} from 'react-admin';
import {useWatch} from 'react-hook-form';

const Form = () => {
    const id = useWatch({name: 'breed_id'}) as DogBreedData['id'] | undefined;
    const {data: breedsData} = useGetList<DogBreedData>('breeds', {});
    const {
        field: {
            // @ts-expect-error: useInput type is wrong
            value: breedIds,
            onChange: onBreedIdsChange,
            onBlur: onBreedIdsBlur,
        },
    } = useInput({source: 'breed_ids'});
    const {data, isPending} = useGetOne('rules', {id});

    const handleValuesChange = useCallback(
        (values: number[]) => {
            onBreedIdsChange(values);
            onBreedIdsBlur();
        },
        [onBreedIdsBlur, onBreedIdsChange]
    );

    useEffect(() => {
        if (data?.breed_ids) {
            onBreedIdsChange(data.breed_ids);
        }
    }, [data?.breed_ids, onBreedIdsChange]);

    return (
        <>
            <ReferenceInput source="breed_id" reference="breeds">
                <AutocompleteInput label="Choose Breed" />
            </ReferenceInput>
            <BreedList
                items={breedsData}
                disabledId={id}
                onValuesChange={handleValuesChange}
                values={breedIds}
                disabled={typeof id !== 'number' || isPending}
            />
        </>
    );
};

export const RuleCreate = () => (
    <Create title="Create Breed Rule">
        <SimpleForm>
            <Form />
        </SimpleForm>
    </Create>
);
